import { Box, Button, Grid, TextField } from '@mui/material'

function LoginPage() {

    const form ={
        username:''
        ,password:''
    }
    const handleInput = (e)=>
    {   form = {[e.target.name]: e.target.value}
       
    }
    const handleLogin = ()=>
    {
        console.log(form)
    }
    return (
        <Box
            container
            sx={{ display: 'flex', justifyContent: 'center', mt: 30 }}
        >
            <form>
                <Grid container>
                    <Grid item xs={12} sx={{ width: 1 / 2 }}>
                        <TextField
                            placeholder="Username"
                            helperText="Your username"
                            name='username'
                           
                            onChange={(event)=>{handleInput(event)}}
                        >
                            username
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sx={{ width: 1 / 2 }}>
                        {' '}
                        <TextField
                            placeholder="Password"
                            helperText="Your password"
                            name='password'
                           
                            onChange={(event)=>{handleInput(event)}}
                        >
                            username
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sx={{ width: 1 / 2 }}>
                        {' '}
                        <Button type="submit" onClick={()=>handleLogin()}> Login</Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default LoginPage
