import logo from './logo.svg';
import './App.css';
import { GlobalStyles } from '@mui/material';
import Main from './pages/home';
import MainRoutes from './Routes';
import Header from './pages/user/header';
import AppTheme from './AppTheme';
function App() {
  return (
    <>
    
    <GlobalStyles/>
    
    <Header/>
    <MainRoutes/>
    
  </>
  );
}

export default App;
