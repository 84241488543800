import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Toolbar,
} from "@mui/material";
import { green } from "@mui/material/colors";
import indexDB from "../database/indexDB";
import { create } from "@mui/material/styles/createTransitions";
import Header from "./user/header";

function Main() {
  const form = {
    userName: "hehe",
    age: 19,
  };
  const resgisterUser = (e) => {
    indexDB.onupgradeneeded(e);
  };
  return (
    <Box>
      <Header></Header>
      <Toolbar>
        <TextField
          placeholder="nhap vao day "
          borderRadius={5}
          sx={{ bgcolor: "purple", borderRadius: 0 }}
        />
        <Button
          onClick={(event) => {
            indexDB.onupgradeneeded(event);
          }}
        >
          Click to connect indexDB
        </Button>
      </Toolbar>
      <Grid container>
        <Grid item flex={4} sx={{ bgcolor: "black" }}>
          haha
        </Grid>
        <Grid item flex={4}>
          {" "}
          hehe
        </Grid>
      </Grid>
    </Box>
  );
}

export default Main;
