const indexDB = window.indexedDB.open("UserDB", 1);
let db;
// indexDB.onupgradeneeded = (event) => {
//   db = event.target.result;
//   db.createObjectStore(collectionName, {
//     autoIncrement: true,
//   });
// };
const customerData = [
    { ssn: "444-44-4444", name: "Bill", age: 35, email: "bill@company.com" },
    { ssn: "555-55-5555", name: "Donna", age: 32, email: "donna@home.org" },
  ];
indexDB.onupgradeneeded = (event) => {
    // Save the IDBDatabase interface
    const db = event.target.result;
    console.log(db)
  
    // Create an objectStore for this database
    const objectStore = db.createObjectStore("customers", { keyPath: "ssn" });
    objectStore.createIndex("name", "name", { unique: false });
    objectStore.createIndex("email", "email", { unique: true });
    objectStore.transaction.oncomplete = (event) => {
        // Store values in the newly created objectStore.
        const customerObjectStore = db
          .transaction("customers", "readwrite")
          .objectStore("customers");
        customerData.forEach((customer) => {
          customerObjectStore.add(customer);
        });
      };

  };
indexDB.onerror = (event) => {
  console.error("Why didn't you allow my web app to use IndexedDB?!");
};
indexDB.onsuccess = (event) => {
  db = event.target.result;
};

export  default indexDB;