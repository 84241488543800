import { AppBar, Avatar, Box, Button, Icon, IconButton, Tooltip } from '@mui/material'
import { red } from '@mui/material/colors'
import { Link } from 'react-router'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
function Header() {
    return (
        <AppBar sx={{ bgcolor: red,justifyItems: 'flex-end' }}>
            <Tooltip>
            <Box flex={10} sx={{ bgcolor: 'black',justifySelf: 'flex-start' }}>
                
                <Link to="/home">
                <HomeOutlinedIcon sx={{fontSize:50}} />
                    <Button sx={{ bgcolor: 'yellow' }}>Home</Button>
                </Link>
                <Avatar></Avatar>
                <Link to="/login"> 
                    <Button sx={{ bgcolor: 'yellow', mr: 20 }}>Login</Button>
                </Link>
                <Icon>
                    <IconButton />
                </Icon>
            </Box>
            </Tooltip>
        </AppBar> 
    )
}

export default Header
