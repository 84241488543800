import { Route, Router, Routes } from "react-router";
import Main from "../pages/home";
import LoginPage from "../pages/user/login";
import Header from "../pages/user/header";

function MainRoutes() {
    return ( 
        <Routes>
         
            <Route path='*' element={<Main/>}/>
            <Route path='/home' element={<Main/>}/>
            <Route path='/login' exact  element={<LoginPage/>}/>
        </Routes>
     );
}

export default MainRoutes;